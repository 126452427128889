"use client";

import {
  colorsTuple,
  createTheme,
  type CSSVariablesResolver,
  DEFAULT_THEME,
  type DefaultMantineColor,
  type MantineColorsTuple,
} from "@mantine/core";
import { Inter } from "next/font/google";

type ExtendedCustomColors = "adinPrimary" | "adinSecondary" | "adinDark" | DefaultMantineColor;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

const InterFont = Inter({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export const theme = createTheme({
  primaryColor: "adinPrimary",
  colors: {
    adinPrimary: colorsTuple("#0c7948"),
    adinSecondary: colorsTuple("#fffb91"),
    adinSecondaryHover: colorsTuple("#fffa69"),
    adinDark: colorsTuple("#545454"),
  },
  fontFamily: `${InterFont.style.fontFamily}, ${DEFAULT_THEME.fontFamily}`,
  cursorType: "pointer",
});

export const cssVariablesResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-color-primary": theme.colors.adinPrimary[0],
  },
  light: {},
  dark: {},
});
