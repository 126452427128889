import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(header-clay-court)/blog/NewsletterSignUp.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer/Footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/HeaderLayout/HeaderLayout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/keyless-cookie-sync.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["ActionIcon"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/ActionIcon/ActionIcon.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["BackgroundImage"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/BackgroundImage/BackgroundImage.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Button/Button.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Group"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Group/Group.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Stack"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Stack/Stack.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Title"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Title/Title.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
