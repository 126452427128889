"use client";

import { Carousel, CarouselSlide } from "@mantine/carousel";
import { Avatar, Container, Paper, Text } from "@mantine/core";
import { IconChevronLeft, IconChevronRight, IconQuote } from "@tabler/icons-react";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";

import classes from "./Testimonials.module.css";

const data = [
  {
    name: "Veni Makovski",
    image: "veni.jpg",
    text: "Thanks to AD-IN, I am now connected with many tennis partners. AD-IN helps tennis fans connect, play and just have a good time meeting like-minded people.",
  },
  /*
  {
    name: "Amy Xu",
    image: "amy.jpg",
    text: "AD-IN has been such a great way for me to build my tennis community! From finding hitting partners to navigating court reservations, tennis holidays, stringing resources… AD-IN has got it all! What a gem :)",
  },
  */
  {
    name: "Arik Zurabian",
    image: "arik.jpg",
    text: "Thank you so much for establishing AD-IN. Our community helped me meet people, have wonderful time with them on and off the court and obtain more information and practical tips on tennis life in NYC and beyond.",
  },
  {
    name: "Ingrid Wright",
    image: "ingrid.jpg",
    text: "I am back in the game! After 40+ years of not playing tennis at all I stumbled upon AD-IN. I have been given support, information, people to play with and activities that are social and exceptional. I feel fortunate to be part and member of this group.",
  },
  {
    name: "Woody Schneider",
    image: "woody.jpg",
    text: "I was introduced to this really nice group whose sole purpose is to form new relationships through tennis. If you are looking for new people to play with or make new friends with the common interest of tennis I highly recommend you check them out.",
  },
];

export default function Testimonials() {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  return (
    <Carousel
      px="3rem"
      withIndicators
      align="center"
      loop
      draggable
      withControls
      controlSize={40}
      // Check if this is fixed by: https://github.com/facebook/react/issues/30745
      // eslint-disable-next-line react-compiler/react-compiler
      // plugins={[autoplay.current]}
      onMouseEnter={() => autoplay.current.stop()}
      onMouseLeave={() => autoplay.current.reset()}
      nextControlIcon={<IconChevronRight />}
      previousControlIcon={<IconChevronLeft />}
      styles={{
        indicators: {
          position: "static",
          marginTop: "30px",
        },
        indicator: {
          width: "8px",
          height: "8px",
          // Fix transparent background color only when deployed
          backgroundColor: "var(--mantine-color-bright)",
        },
        control: {
          backgroundColor: "var(--mantine-color-adinSecondary-filled)",
          opacity: "initial",
          border: "none",
        },
      }}
    >
      {data.map((testimonial) => (
        <Testimonial key={testimonial.name} testimonial={testimonial} />
      ))}
    </Carousel>
  );
}

function Testimonial({
  testimonial: { name, image, text },
}: { testimonial: { name: string; image: string; text: string } }) {
  return (
    <CarouselSlide>
      <Container size="sm">
        <Paper style={{ height: "100%", position: "relative" }}>
          <Avatar
            src={`/images/testimonials/${image}`}
            name={name}
            color="initials"
            alt={name}
            size={120}
            radius={120}
            mx="auto"
            mb="md"
          />
          <IconQuote size={55} stroke={1.5} color="var(--mantine-color-adinPrimary-filled)" className={classes.icon} />
          <Text size="xl">{text}</Text>
          <Text>&nbsp;</Text>
          <Text fw="bold">— {name}</Text>
        </Paper>
      </Container>
    </CarouselSlide>
  );
}
