"use client";

import {
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  SignInButton,
  SignUpButton,
  useAuth,
  useOrganization,
  useOrganizationList,
  UserButton,
} from "@clerk/nextjs";
import { ActionIcon, Box, Button, Container, Drawer, Group, Stack, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBallTennis, IconMenu2, IconX } from "@tabler/icons-react";
import cx from "clsx";
import { usePathname } from "next/navigation";

import AppLogo from "@/components/AppLogo/AppLogo";
import { TransitionLink } from "@/components/TransitionLink/TransitionLink";
import { addClerkTermsDisclaimer } from "@/utils/clientHelpers";

import { ColorSchemeToggle } from "../ColorSchemeToggle/ColorSchemeToggle";

import classes from "./Header.module.css";

const personalLinks = [
  { link: "/", alternateLink: "/play-tennis", label: "PLAY TENNIS", isPrivate: false, role: "" },
  { link: "/get-togethers", label: "GET-TOGETHERS" },
  { link: "/organize-games", label: "ORGANIZE GAMES" },
  { link: "/about-us", label: "ABOUT" },
  { link: "/blog", label: "BLOG" },

  // { link: "/my-tennis-profile", label: "MY TENNIS PROFILE", isPrivate: true, role: "" },
];

const orgLinks = [
  { link: "/admin", alternateLink: undefined, label: "ADMIN", isPrivate: true, role: "org:admin" },
  { link: "/admin/events", label: "EVENTS", isPrivate: true, role: "org:admin" },
];

interface HeaderProps {
  darkBackground?: boolean;
}

export function Header(props: HeaderProps) {
  const { darkBackground } = props;

  const pathname = usePathname();
  const { userMemberships } = useOrganizationList({ userMemberships: true });
  const [mobileMenuOpened, { open: mobileMenuOpen, close: mobileMenuClose }] = useDisclosure(false);

  return (
    <header className={classes.header}>
      <Container size="lg" className={classes.inner}>
        <AppLogo withNavigation className={classes.logo} />

        <Box className={classes.links} visibleFrom="xs">
          <NavigationMenu darkBackground={darkBackground} />
        </Box>

        <Group wrap="nowrap">
          <SignedIn>
            <Tooltip label="My Tennis Profile" withArrow arrowSize={10}>
              <ActionIcon size={30} radius="xl" component={TransitionLink} href="/my-tennis-profile">
                <IconBallTennis size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            {(userMemberships?.count ?? 0) >= 1 ? (
              <OrganizationSwitcher
                afterSelectOrganizationUrl={orgLinks[0].link}
                afterSelectPersonalUrl={personalLinks[0].link}
                appearance={{
                  elements: {
                    organizationSwitcherTrigger: {
                      padding: 0,
                      backgroundColor: "initial",

                      ".cl-userPreviewTextContainer": { display: "none" },
                      ".cl-organizationPreviewTextContainer": { display: "none" },
                    },
                    organizationSwitcherTriggerIcon: { display: "none" },
                    avatarBox: {
                      width: 28,
                      height: 28,
                      borderRadius: "50%",
                    },
                  },
                }}
              />
            ) : (
              <UserButton userProfileMode="modal" defaultOpen={false} />
            )}
          </SignedIn>

          <SignedOut>
            <SignInButton mode="modal" forceRedirectUrl={pathname} signUpForceRedirectUrl={pathname}>
              <Button
                size="md"
                color="var(--mantine-color-white)"
                c="var(--mantine-color-black)"
                classNames={{
                  root: classes.loginButton,
                  label: classes.loginButtonLabel,
                }}
                onClick={addTermsDisclaimer}
              >
                LOGIN
              </Button>
            </SignInButton>

            <Box visibleFrom="xs">
              <SignInButton mode="modal" forceRedirectUrl={pathname} signUpForceRedirectUrl={pathname}>
                <Button
                  size="md"
                  variant="transparent"
                  c="var(--mantine-color-white)"
                  classNames={{
                    root: classes.loginButton,
                    label: classes.loginButtonLabel,
                  }}
                  onClick={addTermsDisclaimer}
                >
                  SIGN UP
                </Button>
              </SignInButton>
            </Box>
          </SignedOut>

          <Box hiddenFrom="xs">
            <ActionIcon
              size={40}
              radius="xl"
              color="white"
              c="black"
              className={classes.mobileMenu}
              onClick={mobileMenuOpen}
            >
              <IconMenu2 size={25} stroke={1.5} />
            </ActionIcon>

            <Drawer
              opened={mobileMenuOpened}
              onClose={mobileMenuClose}
              position="top"
              size="100%"
              title={<AppLogo withNavigation width="60px" onClick={mobileMenuClose} />}
              closeButtonProps={{
                icon: (
                  <ActionIcon
                    component="div"
                    size={34}
                    radius="xl"
                    mr="md"
                    color="var(--mantine-color-bright)"
                    c="var(--mantine-color-body)"
                  >
                    <IconX size={26} stroke={2} />
                  </ActionIcon>
                ),
              }}
              styles={{
                body: {
                  paddingLeft: "5px",
                  paddingRight: "5px",
                },
              }}
            >
              <Stack pt="md">
                <NavigationMenuMobile mobileMenuClose={mobileMenuClose} />

                <Box className={classes.mobileMenuFooter}>
                  <SignUpButton mode="modal">
                    <Button
                      w="100%"
                      size="xl"
                      color="adinSecondary"
                      c="black"
                      mt={0}
                      classNames={{
                        root: classes.joinButton,
                        label: classes.joinButtonLabel,
                      }}
                      style={{
                        "--button-hover": "var(--mantine-color-adinSecondaryHover-filled)",
                      }}
                    >
                      JOIN US FOR FREE
                    </Button>
                  </SignUpButton>

                  <SignInButton mode="modal" forceRedirectUrl={pathname} signUpForceRedirectUrl={pathname}>
                    <Button
                      w="100%"
                      size="xl"
                      color="var(--mantine-color-bright)"
                      c="var(--mantine-color-body)"
                      mt={20}
                      classNames={{
                        root: classes.loginButton,
                        label: classes.loginButtonLabel,
                      }}
                      onClick={addTermsDisclaimer}
                    >
                      LOGIN
                    </Button>
                  </SignInButton>
                </Box>
              </Stack>
            </Drawer>
          </Box>

          <ColorSchemeToggle />
        </Group>
      </Container>
    </header>
  );
}

function NavigationMenu({ darkBackground, skip, take }: { darkBackground?: boolean; skip?: number; take?: number }) {
  const pathname = usePathname();
  const { isSignedIn, has } = useAuth();
  const { organization } = useOrganization();

  const links = organization ? orgLinks : personalLinks;

  return (
    <Group gap={0} justify="flex-end" className={cx(classes.mainLinks, darkBackground && classes.mainLinksDark)}>
      {links
        .filter((item) => !item.isPrivate || isSignedIn)
        .filter((item) => !item.role || has?.({ role: item.role }))
        .slice(skip ?? 0, (skip ?? 0) + (take ?? links.length))
        .map((item) => (
          <TransitionLink href={item.link} key={item.link} className={classes.mainLink}>
            <span
              className={classes.mainLinkText}
              data-active={
                pathname === item.link ||
                (item.link !== links[0].link && pathname?.startsWith(item.link)) ||
                (item.alternateLink &&
                  (pathname === item.alternateLink ||
                    (item.alternateLink !== links[0].alternateLink && pathname?.startsWith(item.alternateLink)))) ||
                undefined
              }
            >
              {item.label}
            </span>
          </TransitionLink>
        ))}
    </Group>
  );
}

function NavigationMenuMobile({ mobileMenuClose }: { mobileMenuClose: () => void }) {
  const pathname = usePathname();
  const { isSignedIn, has } = useAuth();
  const { organization } = useOrganization();

  const links = organization ? orgLinks : personalLinks;

  return (
    <Stack style={{ flexGrow: 1 }} className={cx(classes.mainLinks, classes.mainLinksMobile)}>
      {links
        .filter((item) => !item.isPrivate || isSignedIn)
        .filter((item) => !item.role || has?.({ role: item.role }))
        .map((item) => (
          <TransitionLink href={item.link} key={item.link} onClick={mobileMenuClose} className={classes.mainLink}>
            <span
              className={cx(classes.mainLinkText, classes.mainLinkTextMobile)}
              data-active={
                pathname === item.link ||
                (item.link !== links[0].link && pathname?.startsWith(item.link)) ||
                (item.alternateLink &&
                  (pathname === item.alternateLink ||
                    (item.alternateLink !== links[0].alternateLink && pathname?.startsWith(item.alternateLink)))) ||
                undefined
              }
            >
              {item.label}
            </span>
          </TransitionLink>
        ))}
    </Stack>
  );
}

function addTermsDisclaimer() {
  const modalNode = document.querySelector<HTMLElement>(".cl-modalContent .cl-signUp-root");
  if (!modalNode) {
    setTimeout(() => addTermsDisclaimer(), 100);
    return;
  }

  addClerkTermsDisclaimer(modalNode);
}
