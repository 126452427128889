"use client";

import { useOrganization } from "@clerk/nextjs";
import { Affix, Button } from "@mantine/core";
import { IconBrandWhatsapp } from "@tabler/icons-react";
import Link from "next/link";

import { env } from "@/env-client";

export function WhatsAppFloatingButton() {
  const { organization } = useOrganization();

  if (!env.NEXT_PUBLIC_ADIN_WHATSAPP_GROUP_URL) return null;
  if (organization) return null;

  return (
    <Affix zIndex={199} position={{ bottom: 40, right: 40 }}>
      <Button
        component={Link}
        href={env.NEXT_PUBLIC_ADIN_WHATSAPP_GROUP_URL}
        target="_blank"
        rightSection={<IconBrandWhatsapp size={40} stroke={1} />}
        h={50}
      >
        Join the AD-IN
        <br />
        community!
      </Button>
    </Affix>
  );
}
