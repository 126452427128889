"use client";

import { ClerkProvider } from "@clerk/nextjs";
import { dark as clerkDarkTheme } from "@clerk/themes";
import { useComputedColorScheme } from "@mantine/core";

export function ThemedClerkProvider({ children }: { children: React.ReactNode }) {
  const computedColorScheme = useComputedColorScheme("light");

  return (
    <ClerkProvider
      dynamic
      appearance={{
        baseTheme: computedColorScheme === "dark" ? clerkDarkTheme : undefined,
        variables: {
          // ToDo: get color from theme
          // colorPrimary: theme.primaryColor ? theme.colors?.adinBall?.[9] : undefined,
          colorPrimary: "#0c7948",
        },
        layout: {
          termsPageUrl: "/terms-of-service",
          privacyPageUrl: "/privacy-policy",
          logoPlacement: "none",
          shimmer: true,
        },
        elements: {
          headerSubtitle: {
            display: "none",
          },
        },
      }}
    >
      {children}
    </ClerkProvider>
  );
}
